import React from 'react';
import Main from '../components/Main';
import SlidingImageAnimation from '../components/SlidingImagesAnimation';
import { Content } from '../components/Content';


const Home = () => {
    return (
        <>
            <Main />
            <SlidingImageAnimation/>
            <Content />
        </>
    )
}

export default Home