import React from "react";
import logo from "../assets/images/DINIKA PUBLICATIONS.png";
import "./Content.css";
import book2 from "../assets/images/BOOK.png";



export const Content = () => {
  return (
    <div className=" bg-[#fef2f2]">
    <div className="container mx-auto my-4">
      <h4 className="py-5">Latest From Bhavya Publications Blog</h4>
      <div className="grid grid-cols-3 gap-4 mb-5" id="slide">
        <img className="" src={book2}></img>
        <img className="" src={book2}></img>
        <img className="" src={book2}></img>
      </div>
      </div>
      <hr className="py-9"/>
      <h4 className="py-9">Our Top Books For 2024</h4>
      <div className="grid grid-cols-4 gap-4 ms-8" id="slide">
      <img className=" " src={logo}></img>
        <img className="" src={logo}></img>
        <img className="" src={logo}></img>
        <img className="" src={logo}></img>
    </div>
    </div>
  );
};
